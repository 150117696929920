import {
	InMemoryCache,
	defaultDataIdFromObject,
	TypePolicies,
	StoreObject,
} from '@apollo/client';
import { KeyFieldsFunction } from '@apollo/client/cache/inmemory/policies';
import {
	typenameMapping,
	typenamesForCustomMapping,
	typenamesToSafelyIgnoreInReporting,
} from './cacheTypenameMapping';
import { isDevelopment } from '../../lib/constants';

/* This method turns the typenameMapping object into the things Apollo uses to transform the graphql requests into cache
This results in basically a long list of `if typename = X or Y then the cache key is Z:id, otherwise we just return
whatever defaultDataIdFromObject spits out as the cache key */
const typenameMappingToDataIdResolver =
	(): KeyFieldsFunction => ( responseObject ) => {
		if ( !responseObject.__typename ) {
			// Mutations do not have typenames on the outer-most part, and that's what this condition traps
		} else if ( responseObject.__typename === 'InquiryFormField' ) {
			const typename = responseObject.__typename;
			// A custom id for the InquiryFormField field type
			return `${ typename }:${ responseObject.type }`;
		} else {
			const typename = responseObject.__typename;
			// Check if typename is in mapping object
			const entries = Object.entries( typenameMapping );
			for ( const [ to, from ] of entries ) {
				if ( from.includes( typename ) ) {
					return `${ to }:${ responseObject.id }`;
				}
			}
			/* If not, check if we need a custom key for the mapping, like, where there is no ID on the object.
		ex: ResponseObjectForGetContractWhere's ID should be requestingUserBaseId_contractID */
			if ( typename in typenamesForCustomMapping ) {
				const customIds = typenamesForCustomMapping[ typename ];
				const customId = customIds
					.reduce(
						( acc1: string[], key: string ) => [
							...acc1,
							key.split( '.' ).reduce(
								( acc2, specificKey ) =>
									( acc2[ specificKey ] || acc2 ) as StoreObject, // this is to trigger ultimate return type of string
								{ ...responseObject } as StoreObject // this copy is because responseObject is ReadOnly
							) as unknown as string, // this is because we can actually get undefined or string, so make sure the fields exist!
						],
						[]
					)
					.join( '_' );
				return `${ typename }:${ customId }`;
			}
			// Log if we're going to use the default treatment, but only do this console logging in development environments
			if ( isDevelopment ) {
				const typenameIsNotOfConcern =
					typenamesToSafelyIgnoreInReporting.includes(
						responseObject.__typename
					);
				const typenameIsKeyOfCustomMap = Object.keys( typenameMapping ).includes(
					responseObject.__typename
				);
				if ( !typenameIsKeyOfCustomMap && !typenameIsNotOfConcern ) {
					// And if we're in development and the typename is neither a key nor a value in typenameMapping, announce it
					console.info(
						`handling ${ responseObject.__typename } with the default apollo cache ID handler:`,
						responseObject
					);
				}
			}
		}
		return defaultDataIdFromObject( responseObject );
	};

/* this is to instruct Apollo to merge data rather than replace data.
- So if the data associated with an typename of ABC and that maps to a cache name of HIJ
- and ID has Field a and b
- and something comes in with a typename of DEF and that also maps to the cache name of HIJ
- and that same ID has Field b and c but not a
- we don't want to blow away that a data, just merge in the new b and c into that a and b
So you end up with lines like:
`Contract: { merge: true, keyFields: false }, Invoice: { merge: true, keyFields: false }, etc`
*/
const typenameMappingToTypePolicies = (): TypePolicies =>
	Object.keys( typenameMapping ).reduce(
		( acc, key ) => ( {
			...acc,
			[ key ]: {
				/* This merges data - so if Signature has a type prop and a SignatureForGetContractWhere query comes
				back without one, we merge those results in cache so it still has that type for that ID
				https://www.apollographql.com/docs/react/caching/cache-field-behavior
				/#defining-a-merge-function-at-the-type-level */
				merge: true,
			},
		} ),
		{
			FormattedGlobalVariables: { keyFields: false, merge: false },
			StripeInvoicesList: { keyFields: false, merge: false },
			PlanCost: { keyFields: false, merge: false },
			OrgUsers: { keyFields: false, merge: true },
			OrganizationWithCount: {
				users: { keyFields: false, merge: true },
			},
			Organization: {
				users: { keyFields: false, merge: true },
			},
			UsersForGetContractWhere: { keyFields: false, merge: true },
			CustomerSignatureContactOrganizationUsers: {
				keyFields: false,
				merge: true,
			},
		} as TypePolicies
	);

// Here's the actual cache
export const cache = new InMemoryCache( {
	/* this is where we'd add special-case cache keys and the like - like, if
  id alone wasn't enough to identify something in a unique way, or if there was no id */
	dataIdFromObject: typenameMappingToDataIdResolver(),
	typePolicies: typenameMappingToTypePolicies(),
	possibleTypes: typenameMapping,
} );
