import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import Link, { LinkProps as NextLinkProps } from 'next/link';
import React from 'react';
import MuiSpinner from '../MuiSpinner';
import styles from './MuiButton.module.scss';

/* MuiButton is a general-purpose button replacement for our RPC styled-component buttons.
It should handle switching to a navigational <a> tag when href is supplied, and a <button>
otherwise, so that nextjs is happy and we dont 'get total page reloads just to navigate
client-side, improving perceived performance */

type LinkProps = Pick<ButtonProps, 'startIcon' | 'variant'> &
Pick<
React.DetailedHTMLProps<
React.AnchorHTMLAttributes<HTMLAnchorElement>,
HTMLAnchorElement
>,
'className' | 'ref' | 'children' | 'target'
> &
NonNullable<Pick<NextLinkProps, 'href'>> & {
	color?: string;
	disabled?: boolean;
	loading?: boolean;
};

type ButtonProps = Pick<
LoadingButtonProps,
| 'onClick'
| 'disabled'
| 'variant'
| 'className'
| 'ref'
| 'color'
| 'children'
| 'startIcon'
| 'type'
| 'sx'
> & {
	loading?: boolean;
};

export type MuiButtonProps = ( LinkProps | ButtonProps ) & {
	// Props common to both link and button versions of this component
	id?: string;
	'data-cy'?: string;
};

/* non-navigational buttons should be buttons, and navigational "buttons" should be links
The MUI "Link" claims that if there's no href on it it spits out a button, but I did not find that to be true */
const MuiButton: React.FC<MuiButtonProps> = ( props ) => {
	if ( 'href' in props && props.href ) {
		const {
			ref,
			href,
			startIcon,
			children,
			variant,
			color,
			className,
			disabled,
			loading,
			...linkProps
		} = props;
		return (
			<Link
				href={ disabled || loading ? 'javascript:void(0)' : href }
				ref={ ref as React.Ref<HTMLAnchorElement> }
				className={ [
					...( variant
						? [
							styles[
								`${ variant }${
									color
										? color.charAt( 0 ).toUpperCase() + color.slice( 1 )
										: 'Primary'
								}`
							],
						  ]
						: [] ),
					styles.link,
					...( variant ? [ styles[ variant ] ] : [] ),
					...( color ? [ styles[ color ] ] : [ 'primary' ] ),
					...( !children ? [ styles.empty ] : [] ),
					...( startIcon ? [ styles.iconButton ] : [] ),
					...( disabled || loading ? [ styles.disabled ] : [] ),
					...( className ? [ className ] : [] ),
				].join( ' ' ) }
				{ ...linkProps }
			>
				{ loading ? (
					<MuiSpinner className={ styles.spinner } size={ 20 } />
				) : (
					<>
						{ startIcon }
						{ children }
					</>
				) }
			</Link>
		);
	} else if ( !( 'href' in props ) ) {
		// either onClick or href need to be passed in
		const { ref, className, variant, children, ...buttonProps } = props;
		return (
			<LoadingButton
				ref={ ref }
				/* There's a bug in LoadingButton where it doesn't inherit the Muibutton theme
				https://github.com/mui/material-ui/issues/29740 */
				variant={ variant ? variant : 'contained' }
				className={ [
					styles.button,
					...( className ? [ className ] : [] ),
					...( !children ? [ 'empty' ] : [] ),
				].join( ' ' ) }
				{ ...buttonProps }
			>
				{ children }
			</LoadingButton>
		);
	} else {
		return null;
	}
};

export default MuiButton;
