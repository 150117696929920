import React, { useEffect, useMemo, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import styles from './AddressFieldSet.module.scss';

export type GoogleAutoCompleteField = {
	id?: string;
	label: string;
	placeholder?: string;
	onChange: ( arg0: string ) => void;
	setPlace: ( place: google.maps.places.PlaceResult | null ) => void;
	value: string;
	className?: string;
	error: boolean;
	helperText?: string;
	onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
	inputProps?: TextFieldProps['inputProps'];
	country?: string;
	disabled?: boolean;
};

const GoogleAutoCompleteField: React.FC<GoogleAutoCompleteField> = ( {
	id,
	label,
	placeholder,
	setPlace,
	onChange,
	value,
	className,
	error,
	helperText,
	onBlur,
	inputProps,
	country = 'us',
	disabled,
} ) => {
	const [ placeSelection, setPlaceSelection ] =
		useState<google.maps.places.AutocompletePrediction | null>( null );
	const [ placeOptions, setPlaceOptions ] = useState<
	readonly google.maps.places.AutocompletePrediction[] | []
	>( [] );
	const autocompleteService = useRef<google.maps.places.AutocompleteService>();
	const placeService = useRef<google.maps.places.PlacesService>();

	const placesRef = useRef();
	const fetch = useMemo(
		() =>
			debounce(
				(
					request: {
						input: string;
						componentRestrictions: { country: string[] };
					},
					callback: (
						results: readonly google.maps.places.AutocompletePrediction[] | null
					) => void
				) => {
					autocompleteService.current?.getPlacePredictions( request, callback );
				},
				400
			),
		[]
	);

	useEffect( () => {
		if ( !placeService.current && ( window as any ).google ) {
			placeService.current = new (
				window as any
			).google.maps.places.PlacesService( placesRef.current );
		}
	}, [ placeService ] );

	useEffect( () => {
		let active = true;

		if ( !autocompleteService.current && ( window as any ).google ) {
			autocompleteService.current = new (
				window as any
			).google.maps.places.AutocompleteService();
		}
		if ( !autocompleteService.current ) {
			return undefined;
		}
		if ( value === '' ) {
			setPlaceOptions( placeSelection ? [ placeSelection ] : [] );
			return undefined;
		}

		fetch(
			{ input: value, componentRestrictions: { country: [ country ] } },
			(
				results?: readonly google.maps.places.AutocompletePrediction[] | null
			) => {
				if ( active ) {
					let newOptions: readonly google.maps.places.AutocompletePrediction[] =
						[];

					if ( placeSelection ) {
						newOptions = [ placeSelection ];
					}

					if ( results ) {
						newOptions = [ ...newOptions, ...results ];
					}

					setPlaceOptions( newOptions );
				}
			}
		);

		return () => {
			active = false;
		};
	}, [
		placeSelection,
		value,
		fetch,
		country
	] );

	useEffect( () => {
		const getPlace = async () => {
			if ( placeService.current && placeSelection?.place_id ) {
				placeService.current.getDetails(
					{
						placeId: placeSelection.place_id,
					},
					( result: google.maps.places.PlaceResult | null ) => {
						setPlace( result );
					}
				);
			}
		};
		getPlace();
	}, [
		placeSelection,
		placeService,
		setPlace
	] );

	return (
		<>
			<Autocomplete<
			google.maps.places.AutocompletePrediction,
			false,
			true,
			true
			>
				id={ id }
				getOptionLabel={ ( option ) =>
					typeof option === 'string' ? option : option.description
				}
				className={ className }
				options={ placeOptions || [] }
				autoComplete
				freeSolo
				includeInputInList
				disableClearable={ true }
				filterSelectedOptions
				value={ value }
				noOptionsText='No locations'
				disabled={ disabled }
				onChange={ ( _, newValue ) => {
					if ( typeof newValue !== 'string' ) {
						setPlaceOptions(
							newValue ? [ newValue, ...placeOptions ] : placeOptions
						);
						setPlaceSelection( newValue );
					}
				} }
				onInputChange={ ( _, newInputValue ) => {
					onChange( newInputValue );
				} }
				renderInput={ ( params ) => (
					<TextField
						{ ...params }
						label={ label }
						fullWidth
						variant='outlined'
						error={ error }
						helperText={ helperText }
						placeholder={ placeholder }
						onBlur={ onBlur }
						inputProps={ {
							...params?.inputProps,
							...inputProps,
						} }
					/>
				) }
				renderOption={ ( props, option ) => {
					const matches =
						option.structured_formatting.main_text_matched_substrings || [];

					const parts = parse(
						option.structured_formatting.main_text,
						matches.map( ( match: { offset: number; length: number } ) => [ match.offset, match.offset + match.length, ] )
					);

					return (
						<li { ...props }>
							<Grid container alignItems='center'>
								<Grid
									item
									sx={ { width: 'calc(100% - 44px)', wordWrap: 'break-word' } }
								>
									{ parts.map( ( part, index ) => (
										<Box
											key={ index }
											component='span'
											sx={ { fontWeight: part.highlight ? 'bold' : 'regular' } }
										>
											{ part.text }
										</Box>
									) ) }
									<Typography
										variant='body2'
										color='text.secondary'
										className='address-autoselect-option'
									>
										{ option.structured_formatting.secondary_text }
									</Typography>
								</Grid>
							</Grid>
						</li>
					);
				} }
			/>
			<TextField inputRef={ placesRef } className={ styles.hidden }></TextField>
		</>
	);
};

export default GoogleAutoCompleteField;
