import Query from '../../Query.js';
import {
	convertInvoice,
	createBundleObject,
	updateBundleCookieAfterSending,
} from '../../helpers';
import { cloneDeep } from 'lodash';
import {
	ClientUserDetailReturnFields,
	ClientUserReturnFields,
	ContactScalarFields,
	InvoiceReturnFields,
	OrgDetailReturnFields,
	OrgReturnFields,
	OrgUserDetailReturnFields,
} from '../returnFields';
import { requestWithoutToken } from '../request/index.js';
import API from '../index.js';
import rpcShared from '@rockpapercoin/rpc-shared';

const queryReducer = ( constraints, query ) => {
	return [
		...constraints,
		{ uploadAttachment_contains: query },
		{ title_contains: query },
		{
			contact: {
				invitation: {
					OR: [
						{ recipientFirstName_contains: query },
						{ recipientLastName_contains: query },
						{ emailAddress_contains: query },
					],
				},
			},
		},
		{
			contact: {
				customer: {
					clientUser: {
						// search by client name(s)
						OR: [
							{ firstNameOne_contains: query },
							{ lastNameOne_contains: query },
							{ firstNameTwo_contains: query },
							{ lastNameTwo_contains: query },
						],
					},
				},
			},
		},
		{
			contact: {
				customer: { email_contains: query },
			},
		},
	];
};

/**
 * As a planner, approve an invoice for a client.
 *
 * @param {String} id - Invoice ID.
 *
 * @returns {Object} res - { errors, invoice }
 */
const approveInvoice = async function( id ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'approveInvoice',
			params: { where: { id } },
			returnFields: InvoiceReturnFields,
		} )
	);
	if ( errors ) {
		return { errors };
	}

	const invoice = convertInvoice( data.data.approveInvoice );

	return { invoice };
};

/** @typedef { { customer: { id: string, clientUser?: { assignedPlanner?: { id: string; organization: { id: string } } | undefined } | undefined }, assignedMember: { id: string; organization: { id: string } }, vendor: { users: { id: string, role: import('../../../types/generated').TeamMemberRole }[] } } } AxiosInvoiceContact */
/** @typedef { { id: string, status: import('../../../types/generated').InvoiceStatus, contact?: AxiosInvoiceContact | null } } AxiosInvoice */

/**
 * Gets an invoice and converts its data to a front-end friendly shape.
 *
 * @param { Object } where - Where clause for invoice retrieval
 * @param { String } [token=null] - Token for anonymous user to access invoice
 * @param { Boolean } [fullContact=false] - Adds detailed return fields from the invoice's associated contact
 *
 * @returns { Promise< { errors: Array<Error> } | { invoice: AxiosInvoice }> }
 */
const getInvoiceWhere = async function(
	where,
	token = null,
	fullContact = false
) {
	const queryParams = { where };
	const returnFields = cloneDeep( InvoiceReturnFields );

	if ( token ) {
		queryParams.data = { token };
	}

	if ( fullContact ) {
		const contactIndex = returnFields.findIndex(
			( key ) => typeof key === 'object' && 'contact' in key
		);
		if ( contactIndex > -1 ) {
			returnFields[ contactIndex ] = {
				contact: [
					...ContactScalarFields,
					{ assignedMember: OrgUserDetailReturnFields },
					{
						customer: [
							'id',
							'email',
							'userType',
							'stripeCustomerId',
							{
								clientUser: [ ...ClientUserReturnFields, ...ClientUserDetailReturnFields, ],
							},
							{ guestUser: [ { user: [ 'id' ] } ] },
							{ orgUser: [ { user: [ 'id' ] } ] },
						],
					},
					{
						vendor: [
							...OrgReturnFields,
							...OrgDetailReturnFields,
							'cardConvenienceFeePercentage',
						],
					},
				],
			};
		}
	}

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getInvoiceWhere',
			params: queryParams,
			returnFields: returnFields,
		} )
	);
	if ( errors ) return { errors };

	const invoice = data.data.getInvoiceWhere;

	return {
		invoice,
	};
};

/**
 * Get invoices.
 *
 * @param {Object} where - "input InvoiceWhereInput"
 *
 * @returns { Promise< { invoices: Array<object> } | { errors: Array<string> } > } res - { errors, invoices }
 */
const getInvoicesWhere = async function(
	where,
	orderBy = { updatedAt: 'desc' }
) {
	let orderByParam;
	if ( Array.isArray( orderBy ) ) {
		orderByParam = orderBy;
	} else {
		orderByParam = [ orderBy ];
	}

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getInvoicesWhere',
			params: { where, orderBy: orderByParam },
			/* if attachmentUrl is in InvoiceReturnFields we don't want to build that for getInvoicesWhere for
			every invoice in invoices, so skim it off */
			returnFields: [
				{
					invoices: InvoiceReturnFields.filter(
						( field ) => field !== 'attachmentUrl'
					),
				},
			],
		} )
	);

	if ( errors ) return { errors };

	const invoices = data.data.getInvoicesWhere.invoices.map( ( invoice ) =>
		convertInvoice( invoice )
	);

	return { invoices };
};

/**
 * @param { Object } invoiceWhereArgs - GetInvoicesForCustomerInput
 * @param { { [string]: string } } orderBy
 * @param { number | undefined } limit
 * @param { number | undefined } skip
 * @param { [ string ] } queries
 * @param { any } returnFields
 * @returns { Promise<{ errors: Error[] } | { invoices: any[], moreToLoad: boolean, skip: number, count: number }>}
 */
const getInvoicesForCustomer = async function( {
	invoiceWhereArgs,
	orderBy = { updatedAt: 'desc' },
	limit,
	skip,
	queries,
	returnFields,
} ) {
	let orderByParam;
	if ( Array.isArray( orderBy ) ) {
		orderByParam = orderBy;
	} else {
		orderByParam = [ orderBy ];
	}

	const params = {
		where: invoiceWhereArgs,
		orderBy: orderByParam,
	};

	if ( limit ) {
		params.take = limit + 1;
	}
	if ( skip ) {
		params.skip = skip;
	}

	if ( queries && queries.length > 0 ) {
		params.where.OR = queries.reduce( queryReducer, [] );
	}
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getInvoicesForCustomer',
			params,
			returnFields,
		} )
	);

	if ( errors ) return { errors };

	const invoices = data.data.getInvoicesForCustomer.invoices;

	const count = data.data.getInvoicesForCustomer._count;

	const newSkip = skip + invoices.length;

	let moreToLoad = false;
	if ( invoices.length > limit ) {
		invoices.pop();
		moreToLoad = true;
	}

	return {
		invoices,
		moreToLoad,
		skip: newSkip,
		count,
	};
};

const invoiceUpdateHelper = async function( mutationName, id, data ) {
	const where = { id };

	const { data: updateData, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: mutationName,
			params: { data, where },
			returnFields: InvoiceReturnFields,
		} )
	);
	if ( errors ) return { errors };

	return {
		invoice: updateData.data[ mutationName ],
	};
};

const updateInvoice = function( id, data ) {
	return this.invoiceUpdateHelper( 'updateInvoice', id, data );
};

/**
 *
 * @param { String } id of the Invoice
 * @param { {
 *   proposalBundleStatus: string,
 *   contractBundleStatus: string,
 *   invoiceBundleStatus: string
 * } } [ bundle ]
 * @returns { Promise<Object|Error[]> }
 */
const markInvoiceSent = async function( id, bundle ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'markInvoiceSent',
			params: {
				where: { id },
				...( bundle ? { data: { bundle } } : {} ),
			},
			returnFields: InvoiceReturnFields,
		} )
	);
	if ( errors ) {
		return { errors: errors };
	}
	return { invoice: data.data.markInvoiceSent };
};

const markInvoiceVoid = async function( id ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'markInvoiceVoid',
			params: { where: { id } },
			returnFields: InvoiceReturnFields,
		} )
	);
	if ( errors ) {
		return { errors };
	}
	return { invoice: data.data.markInvoiceVoid };
};

const archiveInvoice = function( user, invoiceID ) {
	const field =
		user.user.userType === 'ClientUser'
			? 'archivedByClient'
			: 'archivedByVendor';
	return this.updateInvoice( invoiceID, { [ field ]: true } );
};

const voidInvoice = function( invoiceID ) {
	return this.markInvoiceVoid( invoiceID );
};

/** @typedef {'Draft'|'Disputed'|'Sent'|'PartiallyPaid'|'FullyPaid'|'FullyRefunded'|'FullyPaidPartiallyRefunded'|'Void'} INVOICESTATUS */
/** @typedef { { id: string, userFriendlyStatus: string, title: string, status: INVOICESTATUS, sentAt?: string, dueDate?: string, eventDate?: string, contact: { customer: { id: string }, assignedMember: { id: string, organization: { id: string, name: string } } } } } INVOICE */
/** @typedef {'NeedsSubscription'|'NeedsPayToAccount'|'StripeNeeds'} INVOICESENDFAILURES */

/**
 * Sends or resends invoice, for use where at least the "send" logic is necessary
 * This consolidates the logic so the two places that use this "think" the same about things
 * @param { {
 *   invoice: INVOICE,
 *   onFailure: ( reason: INVOICESENDFAILURES | { errors: Array<string>} ) => void,
 *   onSuccess: () => void,
 *   resend?: boolean
 * } } args
 */
const sendInvoice = async ( {
	invoice,
	onFailure,
	onSuccess,
	resend = false,
} ) => {
	if ( resend ) {
		const res = await API.resendInvoice( invoice.id );
		if (
			'errors' in res &&
			res.errors.some( ( error ) =>
				error.message.includes(
					rpcShared.strings.errorMessages.hasStripeConnectNeeds
				)
			)
		) {
			onFailure( 'NeedsPayToAccount' );
		} else if (
			'errors' in res &&
			res.errors.some( ( error ) =>
				error.message.includes( rpcShared.strings.errorMessages.hasStripeNeeds )
			)
		) {
			onFailure( 'StripeNeeds' );
		} else if ( 'errors' in res ) {
			onFailure( res.errors );
		} else onSuccess();
	} else {
		const bundle = createBundleObject( invoice.contact.id );
		if ( bundle ) {
			bundle.invoiceBundleStatus = 'Sent';
		}
		const res = await API.markInvoiceSent( invoice.id, bundle );
		if (
			'errors' in res &&
			res.errors.some( ( error ) =>
				error.message.includes(
					rpcShared.strings.errorMessages.hasStripeConnectNeeds
				)
			)
		) {
			onFailure( 'NeedsPayToAccount' );
		} else if (
			'errors' in res &&
			res.errors.some( ( error ) =>
				error.message.includes( rpcShared.strings.errorMessages.hasStripeNeeds )
			)
		) {
			onFailure( 'StripeNeeds' );
		} else if ( 'errors' in res ) {
			onFailure( res.errors );
		} else {
			updateBundleCookieAfterSending( {
				contactId: invoice.contact.id,
				invoiceSent: true,
				eventDate: invoice.eventDate,
			} );
			onSuccess();
		}
	}
};

/**
 * Resend an invoice to the assigned client.
 *
 * @param {string} id - ID of the invoice.
 *
 * @returns { Promise< { success: boolean } | { errors: Array<string> }> } res - {errors, success}
 */
const resendInvoice = async function( id ) {
	const res = await this.request(
		new Query( {
			type: 'mutation',
			name: 'sendOrResendInvoice',
			params: { where: { id } },
			returnFields: InvoiceReturnFields,
		} )
	);

	/* Ideally, errors would only appear in the response if there _were_ errors, but...
	And it's still nice to be type-safe */
	if ( 'errors' in res && res.errors ) {
		return { errors: res.errors };
	} else {
		return {
			success: res.data.data.resendInvoice,
		};
	}
};

/**
 * @param { {
 *   queries: Array<string>,
 *   id: string,
 *   limit?: number,
 *   skip?: number,
 *   forSelect?: string
 * } } arg
 * @returns {Promise<Object[]>} results
 */
const searchInvoices = async function( {
	queries,
	id,
	limit,
	skip,
	forSelect = false,
} ) {
	const invoiceWhere = {
		OR: queries.reduce( queryReducer, [] ),
		AND: [
			{
				contact: {
					OR: [ { customer: { id } }, { vendor: { id } } ],
				},
			},
		],
	};

	if ( forSelect ) {
		invoiceWhere.AND.push( { NOT: { status: 'Void' } } );
	}

	const { errors, data } = await this.request(
		new Query( {
			type: 'query',
			name: 'getInvoicesWhere',
			params: {
				where: invoiceWhere,
				take: limit,
				skip,
			},
			/* if attachmentUrl is in InvoiceReturnFields we don't want to build that for getInvoicesWhere for
		every invoice in invoices, so skim it off */
			returnFields: [
				{
					invoices: InvoiceReturnFields.filter(
						( field ) => field !== 'attachmentUrl'
					),
				},
			],
		} )
	);

	if ( errors ) return [];

	const invoices = data.data.getInvoicesWhere.invoices.map( ( invoice ) =>
		convertInvoice( invoice )
	);

	return invoices;
};

/**
 * Fetch a url to upload an invoice attachment too
 * @param { string } invoiceId
 * @param { string } filename
 * @param {string } fileType
 * @returns { Promise< string | { errors: Error[] } > }
 */
const requestInvoiceAttachmentUploadUrl = async (
	invoiceId,
	filename,
	fileType
) => {
	const { errors, data } = await requestWithoutToken(
		new Query( {
			type: 'query',
			name: 'requestInvoiceAttachmentUploadUrl',
			params: {
				where: { id: invoiceId },
				data: {
					filename:
						rpcShared.strings.escapeInvalidCharactersFromFilename( filename ),
					fileType,
				},
			},
			returnFields: [ 'uploadURL' ],
		} )
	);
	if ( errors ) return { errors };
	return data.data.requestInvoiceAttachmentUploadUrl.uploadURL;
};

export {
	approveInvoice,
	archiveInvoice,
	getInvoicesWhere,
	getInvoicesForCustomer,
	getInvoiceWhere,
	markInvoiceSent,
	markInvoiceVoid,
	invoiceUpdateHelper,
	sendInvoice,
	resendInvoice,
	searchInvoices,
	updateInvoice,
	voidInvoice,
	queryReducer,
	requestInvoiceAttachmentUploadUrl,
};
