/**
 * How to use this class
 * Example: get connected clients with names containing 'e' of organizations with names containing an 'f'
 * new Query({
 * 	type: 'query',
 *  name: 'getOrganizationsWhere'
 * 	params: {
 * 		where: {
 * 			name_contains: 'f',
 * 		}
 * 	}
 * 	returnFields: {
 * 		organizations: [
 * 			new Query({
 * 				name 'contacts'
 * 				params: {
 * 					where: {
 * 						customer: {
 * 							clientUser: {
 * 								where: {
 * 									name_contains: 'e'
 * 								}
 * 							}
 * 						}
 * 					}
 * 				}
 * 			})
 * 		]
 * 	}
 * });
 */

export default class Query {
	/**
	 * Some notes on params: excludeEnumParams and disableRemovingSemicolonsFromStringParams were added to support
	 * communicating with Proposals resolvers. it looks like some of the JSON that was being saved frmo unlayer was
	 * getting transformed by our custom Query and Mutation parser, and these params help mitigate that
	 * @param {object} params - Parameters used to construct the Query object.
	 * @param {string} [params.type] - "query" | "mutation"
	 * @param {string} params.name - The name of the query or mutation.
	 * @param {object} [params.params] - The parameters to be passed into the operation.
	 * @param {Array} [params.returnFields] - The fields requested to be returned from the operation.
	 * @param {Array} [params.excludeEnumParams] - The keys exluded from enumParams
	 * @param {boolean} [params.disableRemovingSemicolonsFromStringParams] - Preventing removing semicolons from string
	 * params
	 */
	constructor( params ) {
		this.type = params.type; // either 'query' or 'mutation'
		this.name = params.name;
		this.params = params.params;
		this.returnFields = params.returnFields;
		this.excludeEnumParams = params.excludeEnumParams;
		this.disableRemovingSemicolonsFromStringParams =
			params.disableRemovingSemicolonsFromStringParams || false;

		// String fields which must not be wrapped in quotes.
		this.baseEnumParams = [
			'category',
			'country',
			'date',
			'fileMimeType',
			'fileSource',
			'firstDocument',
			'firstFolder',
			'invitationType',
			'notificationPreference',
			'object',
			'offlinePaymentType',
			'orderBy',
			'orderByDocument',
			'orderByFolder',
			'orgType',
			'ownerType',
			'processor',
			'purpose',
			'redirect',
			'set',
			'skipDocument',
			'skipFolder',
			'state',
			'status',
			'status_in',
			'status_not',
			'type',
			'proposalBundleStatus',
			'contractBundleStatus',
			'invoiceBundleStatus',
			'role',
		];

		this.enumParams = this.excludeEnumParams
			? this.baseEnumParams.filter(
				( item ) => !this.excludeEnumParams.includes( item )
			  )
			: this.baseEnumParams;

		this.preserveLineBreaks = [ 'note' ];
	}

	wrapString( str, key ) {
		if ( this.preserveLineBreaks.includes( key ) ) {
			return `\\"\\"\\"${ str }\\"\\"\\"`;
		} else {
			return `\\"${ str }\\"`;
		}
	}

	formatParams( params = {}, wrap = false, parentIsEnum = false ) {
		let formattedParams = [];
		for ( const key in params ) {
			let param = params[ key ];

			if ( param === null ) {
				param = 'null';
			} else if ( typeof param == 'string' ) {
				param = param.replace( /^[/\s]+|[/\s]+$/g, '' );
				if ( !this.disableRemovingSemicolonsFromStringParams ) {
					param = param.replace( /;/g, '' );
				}
				param = param.replace( /[\s]{2,}/g, ' ' );
				param = param.trim();

				if ( !this.enumParams.includes( key ) && !parentIsEnum ) {
					if ( !this.preserveLineBreaks.includes( key ) ) {
						while ( param.includes( '\\' ) ) {
							param = param.replace( '\\', '' );
						}
					}

					param = param.replace( /"/g, '\\\\\\$&' );
					param = this.wrapString( param, key );
				}
			} else if ( typeof param == 'number' ) {
				param = param.toString();
			} else if ( typeof param == 'object' && param instanceof Date ) {
				if ( !param.getTime() ) {
					param = null;
				} else {
					param = this.wrapString( param.toISOString() );
				}
			} else if ( typeof param == 'object' ) {
				if ( Array.isArray( param ) && this.enumParams.includes( key ) ) {
					if ( key === 'orderBy' ) {
						param = JSON.stringify( param ).replace( /"/g, '' );
					} else {
						param = `[ ${ param.join( ', ' ) } ]`;
					}
				} else {
					/* in order to handle prisma2 things like { category: { in: ['one', 'two] } } the
					   third param to formatParams tells the children that the parent is of an enum type */
					param = this.formatParams(
						param,
						true,
						this.enumParams.includes( key ) || parentIsEnum
					);
				}
			}

			const prepend = Array.isArray( params ) ? '' : `${ key }: `;
			formattedParams.push( prepend + param );
		}

		formattedParams = formattedParams.join( ', ' );

		// convert 'asc' and 'desc' to asc and desc enum values
		formattedParams = formattedParams.replace( /\\["'](de|a)sc\\['"]/gm, '$1sc' );

		return !wrap
			? formattedParams
			: Array.isArray( params )
				? `[ ${ formattedParams } ]`
				: `{ ${ formattedParams } }`;
	}

	buildParams() {
		return this.params ? `( ${ this.formatParams( this.params, false ) } )` : '';
	}

	formatReturnFields( fields ) {
		return fields.map( ( field ) => {
			if ( typeof field == 'object' && !( field instanceof Query ) ) {
				const keys = Object.keys( field );
				if ( keys.length !== 1 ) {
					console.error(
						'Return field arrays must contain only strings and single-key' +
							` objects. In Query name: ${ this.name }`
					);
				}
				if ( !Array.isArray( field[ keys[ 0 ] ] ) ) {
					const UndefinedOrNotArrayError =
						'Sub-fields under value ' +
						`"${ keys[ 0 ] }" is undefined or not an Array. In query "${ this.name }"`;
					console.error( UndefinedOrNotArrayError );
					throw new Error( UndefinedOrNotArrayError );
				}
				return `${ keys[ 0 ] } { ${ field[ keys[ 0 ] ].reduce( ( accumulator, current ) => {
					const accumulated =
						typeof current == 'object'
							? this.formatReturnFields( [ current ] )
							: current;
					return `${ accumulator } ${ accumulated }`;
				}, '' ) } }`;
			}

			return `${ field }`;
		} );
	}

	buildReturnFields() {
		return this.returnFields
			? `{ ${ this.formatReturnFields( this.returnFields ) } }`
			: '';
	}

	wrapQuery( body ) {
		// subqueries are actually just return fields(unwrapped), and don't have a 'type'
		return this.type ? `{${ body }}` : `${ body }`;
	}

	toString() {
		// build the query
		return `${ this.type || '' } ${ this.wrapQuery( `${
			this.name
		} ${ this.buildParams() }
		${ this.buildReturnFields() }` ) }`;
	}
}
