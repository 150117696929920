import React from 'react';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import styles from './StateSelect.module.scss';

type Option = {
	label: string;
	value: string;
};

const options: Option[] = [ { label: 'United States', value: 'US' } ];

type CountrySelect = {
	id?: string;
	className?: string;
	label: string;
	onChange?: (
		event: React.SyntheticEvent<Element, Event>,
		value: string | undefined
	) => void;
	optional?: boolean;
	value?: string;
	error: boolean;
	helperText?: string;
	onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
	inputProps?: TextFieldProps['inputProps'];
	disabled?: boolean;
};

const CountrySelect: React.FC<CountrySelect> = ( {
	id,
	className,
	label,
	onChange,
	value,
	error,
	helperText,
	onBlur,
	inputProps,
	disabled,
} ) => {
	const selectedValue = options.find( ( option ) => option.value === value );
	return (
		// using freeSolo + forcePopupIcon to allow for passing in '' as the value when none is provided, to avoid warning
		<Autocomplete<Option, undefined, true, true>
			id={ id }
			className={ className }
			onChange={ ( e, o ) => onChange?.( e, typeof o === 'string' ? o : o.value ) }
			options={ options }
			value={ selectedValue || '' }
			forcePopupIcon={ true }
			disableClearable={ true }
			disabled={ disabled }
			renderInput={ ( params ) => (
				<TextField
					{ ...params }
					className={ styles.input }
					variant='outlined'
					label={ label }
					fullWidth={ true }
					error={ error }
					helperText={ helperText }
					onBlur={ onBlur }
					inputProps={ {
						...params?.inputProps,
						...inputProps,
					} }
				/>
			) }
		/>
	);
};

export default CountrySelect;
