import * as Types from '../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgOnboardingModal_GetOrgUserQueryVariables = Types.Exact<{
  where: Types.OrgUserWhereUniqueInput;
}>;


export type OrgOnboardingModal_GetOrgUserQuery = { __typename?: 'Query', getOrgUserWhere?: { __typename?: 'OrgUser', id: string, seenOnboardingModalAt?: Date | string | null, organization?: { __typename?: 'Organization', id: string, users?: Array<{ __typename?: 'OrgUser', id: string, role: Types.TeamMemberRole }> | null } | null } | null };

export type OrgOnboardingModal_UpdateOrgUserMutationVariables = Types.Exact<{
  where: Types.OrgUserWhereUniqueInput;
  data: Types.OrgUserUpdateCustomInput;
}>;


export type OrgOnboardingModal_UpdateOrgUserMutation = { __typename?: 'Mutation', updateOrgUser?: { __typename?: 'OrgUser', id: string, seenOnboardingModalAt?: Date | string | null } | null };


export const OrgOnboardingModal_GetOrgUserDocument = gql`
    query orgOnboardingModal_GetOrgUser($where: OrgUserWhereUniqueInput!) {
  getOrgUserWhere(where: $where) {
    id
    seenOnboardingModalAt
    organization {
      id
      users {
        id
        role
      }
    }
  }
}
    `;

/**
 * __useOrgOnboardingModal_GetOrgUserQuery__
 *
 * To run a query within a React component, call `useOrgOnboardingModal_GetOrgUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboardingModal_GetOrgUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgOnboardingModal_GetOrgUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrgOnboardingModal_GetOrgUserQuery(baseOptions: Apollo.QueryHookOptions<OrgOnboardingModal_GetOrgUserQuery, OrgOnboardingModal_GetOrgUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgOnboardingModal_GetOrgUserQuery, OrgOnboardingModal_GetOrgUserQueryVariables>(OrgOnboardingModal_GetOrgUserDocument, options);
      }
export function useOrgOnboardingModal_GetOrgUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgOnboardingModal_GetOrgUserQuery, OrgOnboardingModal_GetOrgUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgOnboardingModal_GetOrgUserQuery, OrgOnboardingModal_GetOrgUserQueryVariables>(OrgOnboardingModal_GetOrgUserDocument, options);
        }
export type OrgOnboardingModal_GetOrgUserQueryHookResult = ReturnType<typeof useOrgOnboardingModal_GetOrgUserQuery>;
export type OrgOnboardingModal_GetOrgUserLazyQueryHookResult = ReturnType<typeof useOrgOnboardingModal_GetOrgUserLazyQuery>;
export type OrgOnboardingModal_GetOrgUserQueryResult = Apollo.QueryResult<OrgOnboardingModal_GetOrgUserQuery, OrgOnboardingModal_GetOrgUserQueryVariables>;
export const OrgOnboardingModal_UpdateOrgUserDocument = gql`
    mutation orgOnboardingModal_UpdateOrgUser($where: OrgUserWhereUniqueInput!, $data: OrgUserUpdateCustomInput!) {
  updateOrgUser(where: $where, data: $data) {
    id
    seenOnboardingModalAt
  }
}
    `;
export type OrgOnboardingModal_UpdateOrgUserMutationFn = Apollo.MutationFunction<OrgOnboardingModal_UpdateOrgUserMutation, OrgOnboardingModal_UpdateOrgUserMutationVariables>;

/**
 * __useOrgOnboardingModal_UpdateOrgUserMutation__
 *
 * To run a mutation, you first call `useOrgOnboardingModal_UpdateOrgUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboardingModal_UpdateOrgUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgOnboardingModalUpdateOrgUserMutation, { data, loading, error }] = useOrgOnboardingModal_UpdateOrgUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrgOnboardingModal_UpdateOrgUserMutation(baseOptions?: Apollo.MutationHookOptions<OrgOnboardingModal_UpdateOrgUserMutation, OrgOnboardingModal_UpdateOrgUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgOnboardingModal_UpdateOrgUserMutation, OrgOnboardingModal_UpdateOrgUserMutationVariables>(OrgOnboardingModal_UpdateOrgUserDocument, options);
      }
export type OrgOnboardingModal_UpdateOrgUserMutationHookResult = ReturnType<typeof useOrgOnboardingModal_UpdateOrgUserMutation>;
export type OrgOnboardingModal_UpdateOrgUserMutationResult = Apollo.MutationResult<OrgOnboardingModal_UpdateOrgUserMutation>;
export type OrgOnboardingModal_UpdateOrgUserMutationOptions = Apollo.BaseMutationOptions<OrgOnboardingModal_UpdateOrgUserMutation, OrgOnboardingModal_UpdateOrgUserMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    