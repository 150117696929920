import React, { useState, useEffect } from 'react';
import CountrySelect from './CountrySelect';
import StateSelect from './StateSelect';
import GoogleAutoCompleteField from './GoogleAutoCompleteField';
import { TextField, Box } from '@mui/material';
import styles from './AddressFieldSet.module.scss';
import classNames from 'classnames';

export type AddressFieldsProps = {
	address: {
		addressLine1?: string;
		addressLine2?: string;
		city?: string;
		state?: string;
		postalCode?: string;
		country?: string;
	};
	validate?: {
		addressLine1?: string;
		addressLine2?: string;
		city?: string;
		state?: string;
		postalCode?: string;
		country?: string;
	};
	onChange: ( values: Record<string, any> ) => void;
	onBlur?: ( e: React.FocusEvent<HTMLInputElement> ) => void;
	className?: string;
	disabled?: boolean;
};

const AddressFields: React.FC<AddressFieldsProps> = ( {
	address,
	validate,
	onChange,
	onBlur,
	className,
	disabled,
} ) => {
	const [ place, setPlace ] = useState<google.maps.places.PlaceResult | null>(
		null
	);

	useEffect( () => {
		if ( place?.address_components ) {
			const streetNumber = place.address_components.find( ( component ) =>
				component.types.includes( 'street_number' )
			)?.long_name;
			const streetName = place.address_components.find( ( component ) =>
				component.types.includes( 'route' )
			)?.long_name;
			const addressLine1 = `${ streetNumber } ${ streetName }`;
			const addressLine2 =
				place.address_components.find( ( component ) =>
					component.types.includes( 'subpremise' )
				)?.long_name || '';
			const city =
				place.address_components.find( ( component ) =>
					component.types.includes( 'locality' )
				)?.long_name || '';
			const state =
				place.address_components.find( ( component ) =>
					component.types.includes( 'administrative_area_level_1' )
				)?.short_name || '';
			const postalCode =
				place.address_components.find( ( component ) =>
					component.types.includes( 'postal_code' )
				)?.long_name || '';
			const country =
				place.address_components.find( ( component ) =>
					component.types.includes( 'country' )
				)?.short_name || '';
			onChange( {
				...address,
				addressLine1,
				addressLine2,
				city,
				state,
				postalCode,
				country,
			} );
		}
	}, [
		address,
		onChange,
		place
	] );

	return (
		<Box className={ classNames( styles.container, className ) }>
			<CountrySelect
				id='country'
				label='Country'
				value={ address.country }
				onChange={ ( _, country ) => onChange( { ...address, country } ) }
				className={ styles.input }
				helperText={ validate?.country }
				error={ validate?.country ? true : false }
				onBlur={ onBlur }
				inputProps={ {
					'data-cy': 'country',
				} }
				disabled={ disabled }
			/>
			<GoogleAutoCompleteField
				id='address-line1'
				label='Address 1'
				placeholder='Enter an address'
				setPlace={ setPlace }
				onChange={ ( addressLine1 ) =>
					onChange( { ...address, addressLine1: addressLine1 || '' } )
				}
				value={ address?.addressLine1 || '' }
				className={ styles.input }
				helperText={ validate?.addressLine1 }
				error={ validate?.addressLine1 ? true : false }
				onBlur={ onBlur }
				inputProps={ { 'data-cy': 'addressLine1' } }
				country={ address.country }
				disabled={ disabled }
			/>
			<TextField
				id='address-line2'
				label='Address 2'
				placeholder='Line 2'
				className={ styles.input }
				value={ address.addressLine2 || '' }
				fullWidth={ true }
				onChange={ ( e ) =>
					onChange( { ...address, addressLine2: e.target.value || '' } )
				}
				helperText={ validate?.addressLine2 }
				error={ validate?.addressLine2 ? true : false }
				onBlur={ onBlur }
				slotProps={ { input: { inputProps: { 'data-cy': 'addressLine2' } } } }
				disabled={ disabled }
			/>
			<TextField
				id='city'
				label='City'
				placeholder='City'
				className={ styles.input }
				value={ address.city || '' }
				fullWidth={ true }
				onChange={ ( e ) => onChange( { ...address, city: e.target.value || '' } ) }
				helperText={ validate?.city }
				error={ validate?.city ? true : false }
				onBlur={ onBlur }
				slotProps={ { input: { inputProps: { 'data-cy': 'city' } } } }
				disabled={ disabled }
			/>
			<Box className={ styles.twoColumnDesktop }>
				<StateSelect
					id='state'
					label='State'
					className={ styles.input }
					value={ address.state }
					onChange={ ( _, state ) => onChange( { ...address, state } ) }
					helperText={ validate?.state }
					error={ validate?.state ? true : false }
					onBlur={ onBlur }
					slotProps={ { input: { inputProps: { 'data-cy': 'state' } } } }
					disabled={ disabled }
				/>
				<TextField
					id='postal-code'
					label='ZIP code'
					placeholder='ZIP code'
					className={ styles.input }
					value={ address.postalCode || '' }
					fullWidth={ true }
					onChange={ ( e ) =>
						onChange( { ...address, postalCode: e.target.value || '' } )
					}
					helperText={ validate?.postalCode }
					error={ validate?.postalCode ? true : false }
					onBlur={ onBlur }
					slotProps={ { input: { inputProps: { 'data-cy': 'postalCode' } } } }
					disabled={ disabled }
				/>
			</Box>
		</Box>
	);
};

export default AddressFields;
