import Query from '../../../Query.js';
import {
	OrgUserDetailReturnFields,
	OrgUserReturnFields,
	UserDetailReturnFields,
} from '../../returnFields';

/**
 * Create a unique OrgUser object
 * @param {Object} data - "input OrgUserCreateInput"
 * @param {String} data.firstName - User's first name. Required.
 * @param {String} data.lastName - User's last name. Required.
 * @param {String} data.phone - User's phone number. Required.
 *
 * @returns {Promise<Object>} res - {user, errors}
 */
const createOrgUser = async function( data ) {
	const { data: createUserData, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createOrgUser',
			params: { data },
			returnFields: OrgUserReturnFields,
		} )
	);
	if ( errors ) return { errors };

	return {
		user: createUserData?.data?.createOrgUser,
	};
};

/**
 * Update a single OrgUser.
 *
 * @param { Object } data - See input type OrgUserUpdateInput
 * @param { String } id - OrgUser's ID.
 *
 * @returns { Promise<{user, errors}> } Response object with user and errors properties.
 */
const updateOrgUser = async function( data, id ) {
	const where = { id };

	const res = await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateOrgUser',
			params: { data, where },
			returnFields: OrgUserReturnFields,
		} )
	);

	return {
		user: res.data.data.updateOrgUser,
		errors: res.data.errors,
	};
};

/**
 * Get the "checkpoints" of the status of a Org Admin's on-boarding.
 *
 * @param {Object} user - User object.
 * @param {String} user.id - ID of org user.
 *
 * @returns {Object} res - { errors, orgUser, status: { hasAcceptedTerms } }
 */
const getOrgMemberForOnboarding = async function( user ) {
	const { errors, data } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrgUserWhere',
			params: { where: { id: user.id } },
			returnFields: [ ...OrgUserDetailReturnFields, { user: [ ...UserDetailReturnFields, 'acceptedTerms' ] }, ],
		} )
	);
	if ( errors ) return { errors };

	const orgUser = data.data.getOrgUserWhere;

	const status = {
		hasAcceptedTerms: orgUser.user.acceptedTerms,
	};

	return { status, orgUser };
};

/**
 * Search for org users with an array of strings.
 *
 * @param {String[]} queries - An array of whole or partial search terms.
 * @param {Int} limit - Max number of results to return.
 * @param {Int} skip - Number of results to skip for pagination.
 *
 * @returns {Promise<Object[]>}
 */
const searchOrgUsers = async function( queries, limit, skip ) {
	const queryReducer = ( constraints, query ) => {
		return [
			...constraints,
			{ firstName_contains: query },
			{ lastName_contains: query },
			{
				organization: {
					OR: [
						{ name_contains: query },
						{ contactEmail_contains: query },
						{ website_contains: query },
					],
				},
			},
		];
	};

	const whereClause = {
		OR: queries.reduce( queryReducer, [] ),
	};

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrgUsersWhere',
			params: {
				where: whereClause,
				take: limit,
				skip,
			},
			returnFields: [ { orgUsers: OrgUserReturnFields } ],
		} )
	);
	if ( errors ) return [];

	const results = data.data.getOrgUsersWhere.orgUsers;

	return results;
};

/**
 * checks whether a given email belongs to an orgUser
 *
 * @param { string } email
 * @returns { Promise< Boolean|Error[]> }
 */
const isOrgUserEmail = async function( email ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrgUsersWhere',
			params: {
				where: { user: { email: email.toLowerCase() } },
				take: 1,
			},
			returnFields: [ { orgUsers: OrgUserReturnFields } ],
		} )
	);
	if ( errors ) return errors;

	if (
		data.data.getOrgUsersWhere.orgUsers &&
		data.data.getOrgUsersWhere.orgUsers.length
	) {
		return true;
	}
	return false;
};

const getOrganizationDefaultOrgUser = async function( id ) {
	const where = { id: id };

	const { org } = await this.getOrganizationWhere( where, [ { users: OrgUserReturnFields }, ] );

	if ( !org ) {
		return { errors: [ 'No org found.' ] };
	}

	return { org };
};

export {
	createOrgUser,
	getOrganizationDefaultOrgUser,
	getOrgMemberForOnboarding,
	isOrgUserEmail,
	searchOrgUsers,
	updateOrgUser,
};
