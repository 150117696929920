import { useDispatch, useSelector } from 'react-redux';
import { gql } from '@apollo/client';
import {
	OrgOnboardingOrganizationFragment,
	OrgOnboardingAddressOrganizationFragment,
	useOrgOnboarding_CreateOrganizationMutation,
	useOrgOnboarding_UpdateOrganizationMutation,
	useOrgOnboarding_RequestOrgProfileImageUploadUrlLazyQuery,
	useOrgOnboarding_GetStripeConnectAccountLazyQuery,
	useOrgOnboarding_CreateStripeConnectAccountMutation,
	useOrgOnboarding_GetOrgUserWhereQuery,
} from './__generated__/useYourBusiness';
import validations from '../../../lib/helpers/validations';
import { showError } from '../../Toast';
import { useState, useRef, useCallback, useEffect } from 'react';
import { FlattenedUser } from '../../../types/user';
import { Country, State, UserType } from '../../../types/generated';
import {
	getEnumKeyByValue,
	NonNullNoTypename,
} from '../../../lib/helpers/apollo';
import { AddressFieldsProps } from '../../../mui/AddressFieldSet';
import { segmentGroup } from '../../../lib/helpers/segment';
import { filterTypenameFromObject } from '../../../lib/helpers/apollo';
import { ProfileImageSelection } from './OrganizationLogoSelector';
import rpcShared from '@rockpapercoin/rpc-shared';
import API from '../../../lib/API';
import { useOrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation } from '../../../types/pages';
import { updateUser } from '../../../redux/actions';
import {
	businessTypeEnumToCorrespondingStripeValue,
	BusinessTypes,
	validateBusinessType,
} from './helpers';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getServicesWhereQuery = gql`
	query OrgOnboarding_getServicesWhere {
		getServicesWhere {
			id
			name
		}
	}
`;

const orgOnboardingAddressOrganizationFragment = gql`
	fragment orgOnboardingAddressOrganization on Organization {
		addressLine1
		addressLine2
		city
		state
		postalCode
		country
	}
`;

const orgOnboardingOrganizationFragment = gql`
	fragment orgOnboardingOrganization on Organization {
		id
		name
		phone
		image
		canReceivePayments
		services {
			id
			name
		}
	}
	${ orgOnboardingAddressOrganizationFragment }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const uploadProfileImageQuery = gql`
	query OrgOnboarding_requestOrgProfileImageUploadUrl(
		$data: RequestImageUploadUrlInput!
	) {
		requestOrgProfileImageUploadUrl(data: $data) {
			uploadURL
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createOrganizationMutation = gql`
	mutation OrgOnboarding_createOrganization(
		$data: OrganizationCustomCreateInput!
	) {
		createOrganization(data: $data) {
			...orgOnboardingOrganization
		}
	}
	${ orgOnboardingOrganizationFragment }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateOrganizationMutation = gql`
	mutation OrgOnboarding_updateOrganization(
		$data: OrganizationCustomUpdateInput!
		$where: OrganizationWhereUniqueInput!
	) {
		updateOrganization(data: $data, where: $where) {
			...orgOnboardingOrganization
		}
		${ orgOnboardingOrganizationFragment }
	}
`;

// This is included purely to get the OrgUser's acceptedTerms status
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getOrgUserWhereQuery = gql`
	query OrgOnboarding_getOrgUserWhere($where: OrgUserWhereUniqueInput!) {
		getOrgUserWhere(where: $where) {
			id
			organization {
				image
			}
			user {
				id
				acceptedTerms
			}
		}
	}
`;

// This is in here just for updating the TOS acceptance date
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateOrgUserMutation = gql`
	mutation OrgOnboarding_updateOrgUserTermsOfAcceptance(
		$data: OrgUserUpdateCustomInput!
		$where: OrgUserWhereUniqueInput!
	) {
		updateOrgUser(data: $data, where: $where) {
			id
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getStripeConnectAccountQuery = gql`
	query OrgOnboarding_getStripeConnectAccount(
		$where: OrganizationWhereUniqueInput!
	) {
		getStripeConnectAccount(where: $where) {
			id
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createStripeConnectAccountMutation = gql`
	mutation OrgOnboarding_createStripeConnectAccount(
		$data: StripeConnectAccountCreationInput!
		$where: OrganizationWhereUniqueInput!
	) {
		createStripeConnectAccount(data: $data, where: $where) {
			id
		}
	}
`;

const useYourBusiness = () => {
	const user: FlattenedUser = useSelector( ( state: any ) => state?.user );
	const loggedInOrgUser =
		user?.isLoggedIn && user.userType === UserType.OrgUser ? user : undefined;
	const dispatch = useDispatch();

	// all of the state for the children
	const [ businessName, setBusinessName ] = useState<
	OrgOnboardingOrganizationFragment['name'] | undefined
	>( loggedInOrgUser?.organization?.name );
	const [ businessType, setBusinessType ] = useState<BusinessTypes | undefined>();
	const [ selectedServices, setSelectedServices ] = useState<
	OrgOnboardingOrganizationFragment['services'] | undefined | null
	>( loggedInOrgUser?.organization?.services );
	const [ address, setAddress ] = useState<
	/* using the presentation component props here rather than Apollo type, because we
	need to do the "massaging" of data into that shape in this hook */
	AddressFieldsProps['address'] | undefined
	>( {
		addressLine1: loggedInOrgUser?.organization?.addressLine1,
		addressLine2: loggedInOrgUser?.organization?.addressLine2,
		city: loggedInOrgUser?.organization?.city,
		state: loggedInOrgUser?.organization?.state,
		country: loggedInOrgUser?.organization?.country,
		postalCode: loggedInOrgUser?.organization?.postalCode,
	} );
	const [ phone, setPhone ] = useState<
	NonNullNoTypename<OrgOnboardingOrganizationFragment['phone']> | undefined
	>( loggedInOrgUser?.organization?.phone || undefined );
	// if image is a string, then it's because we already have uploaded an image
	const [ image, setImage ] = useState<
	ProfileImageSelection | string | undefined
	>();
	const [ termsOfServiceAccepted, setTermsOfServiceAccepted ] = useState<
	boolean | undefined
	>( false );
	const acceptanceOfTermsOfServiceProcessedRef = useRef( false );
	const [ businessNameValidation, setBusinessNameValidation ] = useState<
	string | undefined
	>();
	const [ businessTypeValidation, setBusinessTypeValidation ] = useState<
	string | undefined
	>();
	const [ selectedServicesValidation, setSelectedServicesValidation ] = useState<
	string | undefined
	>();
	const [ addressValidation, setAddressValidation ] = useState<
	AddressFieldsProps['validate'] | undefined
	>();
	const [ phoneValidation, setPhoneValidation ] = useState<string | undefined>();
	const [ termsOfServiceAcceptedValidation, setTermsOfServiceAcceptedValidation, ] = useState<string | undefined>();

	const onBlurValidationEnabledRef = useRef( false );
	const onBlurValidationEnabled = onBlurValidationEnabledRef.current;

	// API calls
	const [ createOrganization ] = useOrgOnboarding_CreateOrganizationMutation();
	const [ updateOrganization ] = useOrgOnboarding_UpdateOrganizationMutation();
	const [ requestOrgProfileImageUploadUrl ] =
		useOrgOnboarding_RequestOrgProfileImageUploadUrlLazyQuery();
	const [ updateOrgUser ] =
		useOrgOnboarding_UpdateOrgUserTermsOfAcceptanceMutation();
	const [ getStripeConnectAccount ] =
		useOrgOnboarding_GetStripeConnectAccountLazyQuery();
	const [ createStripeConnectAccount ] =
		useOrgOnboarding_CreateStripeConnectAccountMutation();
	// we also need to know if the user has accepted the terms of service
	const { data: acceptedTermsData, error: acceptedTermsError } =
		useOrgOnboarding_GetOrgUserWhereQuery( {
			variables: {
				where: {
					id: loggedInOrgUser?.id || '',
				},
			},
			skip: !loggedInOrgUser?.id,
		} );

	useEffect( () => {
		if ( acceptedTermsError ) {
			showError( acceptedTermsError );
		} else if ( acceptedTermsData?.getOrgUserWhere?.user?.acceptedTerms ) {
			// since they've already accepted the terms, update state to indicate that
			setTermsOfServiceAccepted( true );
			acceptanceOfTermsOfServiceProcessedRef.current = true;
		}
		// Here we also want to set the image filename, if a user re-enters this flow
		if ( acceptedTermsData?.getOrgUserWhere?.organization?.image ) {
			const rawFilename = acceptedTermsData.getOrgUserWhere.organization.image;
			const filenameOnly = rawFilename.includes( '/' )
				? rawFilename.split( '/' ).pop()
				: rawFilename;
			setImage( filenameOnly );
		}
	}, [ acceptedTermsError, acceptedTermsData ] );

	const onValidate = useCallback(
		( force = false ) => {
			const businessNameErrors =
				validations.validateCompanyName( businessName ) || undefined; // convert null to undefined, for consistency
			const businessTypeErrors = validateBusinessType( businessType );
			const selectedServicesErrors =
				validations.validateSelectedServices( selectedServices );
			const untranslatedAddressValidation = validations.validateAddress(
				{
					line1: address?.addressLine1,
					line2: address?.addressLine2,
					city: address?.city,
					state: address?.state,
					postalCode: address?.postalCode,
					country: address?.country,
				},
				undefined,
				true
			);
			const addressHasValidationErrors =
				untranslatedAddressValidation &&
				Object.keys( untranslatedAddressValidation ).length > 0;
			const phoneErrors =
				validations.validatePhone( phone || undefined ) || undefined; // correct for null
			const termsOfServiceAcceptedErrors = !termsOfServiceAccepted
				? 'Almost there! Please agree to both RPC’s Terms & Conditions and Stripe’s Terms & Conditions.'
				: undefined;
			if ( onBlurValidationEnabled || force === true ) {
				setBusinessNameValidation( businessNameErrors );
				setBusinessTypeValidation( businessTypeErrors );
				setSelectedServicesValidation( selectedServicesErrors?.selectedServices );
				setAddressValidation( {
					addressLine1: untranslatedAddressValidation?.line1,
					addressLine2: untranslatedAddressValidation?.line2,
					city: untranslatedAddressValidation?.city,
					state: untranslatedAddressValidation?.state,
					postalCode: untranslatedAddressValidation?.postalCode,
					country: untranslatedAddressValidation?.country,
				} );
				setPhoneValidation( phoneErrors );
				setTermsOfServiceAcceptedValidation( termsOfServiceAcceptedErrors );
			}
			if (
				typeof businessNameErrors === 'string' ||
				typeof businessTypeErrors === 'string' ||
				typeof selectedServicesErrors?.selectedServices === 'string' ||
				addressHasValidationErrors ||
				typeof phoneErrors === 'string' ||
				typeof termsOfServiceAcceptedErrors === 'string'
			) {
				return false;
			} else {
				return true;
			}
		},
		[
			address?.addressLine1,
			address?.addressLine2,
			address?.city,
			address?.country,
			address?.postalCode,
			address?.state,
			businessName,
			businessType,
			onBlurValidationEnabled,
			phone,
			selectedServices,
			termsOfServiceAccepted,
		]
	);

	const onNext = useCallback( async () => {
		onBlurValidationEnabledRef.current = true;
		const validationResult = onValidate( true );
		if ( !validationResult ) {
			return false;
		}

		/* If we haven't accepted the TOS, then that's a problem for us - but that is caught
		in the validate function, before we get here. So here we just make the API calls */
		const handleTermsOfServiceAcceptance = async (
			organizationId: string,
			country: Country
		) => {
			const orgUserId = loggedInOrgUser?.id;
			if ( !termsOfServiceAccepted || !businessType || !orgUserId ) {
				return false;
			} else if ( acceptanceOfTermsOfServiceProcessedRef.current ) {
				return true;
			} else {
				// If we've accepted the terms of service and we haven't processed that yet, then do that
				const acceptedTerms = new Date();
				const acceptTermsResponse = await updateOrgUser( {
					variables: {
						data: {
							acceptedTerms,
						},
						where: {
							id: orgUserId,
						},
					},
				} );
				if ( acceptTermsResponse.errors ) {
					acceptTermsResponse.errors.forEach( ( error ) => showError( error ) );
					return false;
				} else {
					acceptanceOfTermsOfServiceProcessedRef.current = true;
					const connectAccountResponse = await getStripeConnectAccount( {
						variables: {
							where: {
								id: organizationId,
							},
						},
					} );
					if ( connectAccountResponse.error ) {
						acceptanceOfTermsOfServiceProcessedRef.current = false;
						showError( connectAccountResponse.error );
						return false;
					} else {
						if ( !connectAccountResponse.data?.getStripeConnectAccount?.id ) {
							const createConnectAccountResponse =
								await createStripeConnectAccount( {
									variables: {
										data: {
											tos_acceptance: {
												date: Math.floor( acceptedTerms.getTime() / 1000 ),
											},
											country,
											business_type:
												businessTypeEnumToCorrespondingStripeValue(
													businessType
												),
										},
										where: {
											id: organizationId,
										},
									},
								} );
							if ( createConnectAccountResponse.errors ) {
								acceptanceOfTermsOfServiceProcessedRef.current = false;
								createConnectAccountResponse.errors.forEach( ( error ) =>
									showError( error )
								);
								return false;
							} else {
								return true;
							}
						} else {
							return true;
						}
					}
				}
			}
		};

		const uploadProfileImageIfExists = async () => {
			if ( image && typeof image !== 'string' ) {
				const uploadUrlRequest = await requestOrgProfileImageUploadUrl( {
					variables: {
						data: {
							fileType: image.imageFile.type,
						},
					},
				} );
				if ( uploadUrlRequest.error ) {
					showError( uploadUrlRequest.error );
					return false;
				} else if (
					uploadUrlRequest.data?.requestOrgProfileImageUploadUrl.uploadURL
				) {
					const uploadResponse = await API.putFile(
						image.imageFile,
						uploadUrlRequest.data?.requestOrgProfileImageUploadUrl.uploadURL
					);
					if ( 'errors' in uploadResponse && uploadResponse.errors ) {
						showError( { message: rpcShared.strings.errorMessages.default } );
						return false;
					} else if ( 'imageUrl' in uploadResponse ) {
						return {
							imagePositionX: image.imagePositionX,
							imagePositionY: image.imagePositionY,
							imageRotation: image.imageRotation,
							imageScale: image.imageScale,
							image: uploadResponse.imageUrl,
						};
					}
				}
			}
		};

		// A helper just so we do the same thing after a create and an update
		const handleSuccessfulAction = async (
			organization: NonNullable<OrgOnboardingOrganizationFragment> & {
				imagePositionX?: number;
				imagePositionY?: number;
				imageRotation?: number;
				imageScale?: number;
			},
			country: Country
		) => {
			/* we can't request an Organization image upload URL unless we're admin, and we're not
			admin until we create the org, so to set the organization image from this flow we have
			to do it at the end as an update */
			const imageData = await uploadProfileImageIfExists();
			if ( imageData ) {
				const response = await updateOrganization( {
					variables: {
						data: {
							...imageData,
						},
						where: {
							id: organization.id,
						},
					},
				} );
				if ( response.errors || !response.data?.updateOrganization ) {
					response.errors?.forEach( ( error ) => showError( error ) );
					return false;
				} else {
					organization.image = imageData.image;
					organization.imagePositionX = imageData.imagePositionX;
					organization.imagePositionY = imageData.imagePositionY;
					organization.imageRotation = imageData.imageRotation;
					organization.imageScale = imageData.imageScale;
				}
			}
			await segmentGroup( {
				...user,
				organization: filterTypenameFromObject( organization ),
			} );
			/* update the user in redux, so that if the stripe stuff fails for some reason we get into
			the updateOrganization flow and don't try to createOrganization for this user again */
			dispatch(
				updateUser( {
					...user,
					organization: filterTypenameFromObject( organization ),
				} )
			);
			// Finally, deal with the terms or acceptance
			return await handleTermsOfServiceAcceptance( organization.id, country );
		};

		const state = address?.state
			? ( getEnumKeyByValue( State, address.state ) as State )
			: undefined;
		const country = address?.country
			? ( getEnumKeyByValue( Country, address.country ) as Country )
			: undefined;

		/* ensure the types of these values below here are never undefined - they should always be
		defined or validation has failed (or possibly the enum values don't match the <select> values
		is possible too...) */
		if ( !state || !country ) {
			return false;
		}

		const presentationAddressToPrismaAddress:
		| NonNullNoTypename<OrgOnboardingAddressOrganizationFragment>
		| undefined =
			address?.addressLine1 &&
			address?.city &&
			state &&
			address?.postalCode &&
			country
				? {
					addressLine1: address.addressLine1,
					// because even though GraphQL says it's required, it's not. GraphQL wants the type to be string
					addressLine2: address.addressLine2 || '',
					city: address.city,
					state,
					postalCode: address.postalCode,
					country,
				  }
				: undefined;

		// Do either the create or the update
		if ( loggedInOrgUser?.organization ) {
			const response = await updateOrganization( {
				variables: {
					data: {
						name: businessName,
						...( selectedServices
							? {
								services: {
									connect: selectedServices.map( ( service ) => ( {
										id: service.id,
									} ) ),
								},
							  }
							: undefined ),
						...presentationAddressToPrismaAddress,
						phone,
					},
					where: {
						id: loggedInOrgUser.organization.id,
					},
				},
			} );
			if ( response.errors || !response.data?.updateOrganization ) {
				response.errors?.forEach( ( error ) => showError( error ) );
				return false;
			} else {
				return await handleSuccessfulAction(
					response.data.updateOrganization,
					country
				);
			}
		} else {
			const name = businessName;
			if (
				!name ||
				!presentationAddressToPrismaAddress ||
				!phone ||
				!selectedServices
			) {
				return false;
			}
			const response = await createOrganization( {
				variables: {
					data: {
						name,
						services: {
							connect: selectedServices.map( ( service ) => ( {
								id: service.id,
							} ) ),
						},
						...presentationAddressToPrismaAddress,
						phone,
					},
				},
			} );
			if ( response.errors || !response.data?.createOrganization ) {
				response.errors?.forEach( ( error ) => showError( error ) );
				return false;
			} else {
				return await handleSuccessfulAction(
					response.data.createOrganization,
					country
				);
			}
		}
	}, [
		onValidate,
		address?.state,
		address?.country,
		address?.addressLine1,
		address?.addressLine2,
		address?.city,
		address?.postalCode,
		loggedInOrgUser?.organization,
		loggedInOrgUser?.id,
		termsOfServiceAccepted,
		businessType,
		updateOrgUser,
		getStripeConnectAccount,
		createStripeConnectAccount,
		image,
		requestOrgProfileImageUploadUrl,
		user,
		dispatch,
		updateOrganization,
		businessName,
		selectedServices,
		phone,
		createOrganization,
	] );

	const onBack = useCallback( () => {
		onBlurValidationEnabledRef.current = false;
	}, [] );

	return {
		businessName,
		setBusinessName,
		businessNameValidation,
		businessType,
		setBusinessType,
		businessTypeValidation,
		selectedServices,
		setSelectedServices,
		selectedServicesValidation,
		address,
		setAddress,
		addressValidation,
		phone,
		setPhone,
		phoneValidation,
		image,
		termsOfServiceAccepted,
		setTermsOfServiceAccepted,
		termsOfServiceAcceptedValidation,
		setImage,
		onNext,
		onBack,
		onValidate,
	};
};

export default useYourBusiness;
