import rpcShared from '@rockpapercoin/rpc-shared';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/actions';
import { Routes } from './routes';
import { FlattenedUser } from '../types/user';
import { UserType } from '../types/generated';

export default function useProfileLinks() {
	const dispatch = useDispatch();
	const user: FlattenedUser = useSelector( ( state: any ) => state.user );

	const orgUser =
		user?.isLoggedIn && user.userType === UserType.OrgUser ? user : undefined;
	const isAdminOrManager = orgUser
		? rpcShared.organizations.isAdminOrManager( orgUser.organization, orgUser )
		: false;

	const items = useMemo( () => {
		return [
			{
				id: 'profile',
				label: 'Profile',
				href: Routes.Profile,
			},
			{
				id: 'admin',
				label: 'Admin',
				href: Routes.Admin,
				isVisible: isAdminOrManager,
			},
			{
				id: 'wallet',
				label: 'My Wallet',
				href: Routes.Wallet,
			},
			{
				id: 'signout',
				label: 'Sign Out',
				onClick: () => {
					dispatch( logout() );
				},
			},
		];
	}, [ isAdminOrManager, dispatch ] );

	return items;
}
