import React, { useCallback, useEffect, useId, useState } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import styles from './MuiModal.module.scss';
import classNames from 'classnames';
import {
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from '@mui/material';
import Icon from '../../elements/icons';
import { useDeviceDetection } from '../../hooks/useDeviceDetection';

export enum MuiModalVariants {
	textAlignCenter = 'textAlignCenter',
}

type OnCloseEventType = Parameters<NonNullable<DialogProps['onClose']>>[0];
type OnCloseReason =
	| Parameters<NonNullable<DialogProps['onClose']>>[1]
	| 'closeIcon'
	| 'fromCode';
export type OnCloseType = (
	event: OnCloseEventType,
	reason: OnCloseReason
) => void;
export type MuiModalProps = {
	canClose?: boolean;
	muiModalProps: Omit<DialogProps, 'children' | 'onClose'> & {
		onClose?: OnCloseType;
	};
	title?: React.ReactNode;
	description?: React.ReactNode;
	children?: React.ReactNode;
	controls?: React.ReactNode;
	variant?: MuiModalVariants;
};

export default function MuiModal( {
	canClose = true,
	muiModalProps,
	title,
	description,
	children,
	controls,
	variant,
}: MuiModalProps ) {
	const { isMobile } = useDeviceDetection();
	const { open: openProp, onClose, ...muiModalPropsToSpread } = muiModalProps;
	const [ open, setOpen ] = useState( openProp );
	// So we can block closing
	const handleOnClose = useCallback(
		( event: OnCloseEventType, reason: OnCloseReason ) => {
			if ( canClose ) {
				setOpen( false );
				onClose?.( event, reason );
			}
		},
		[ canClose, onClose ]
	);
	// So when the prop changes we change this internal state
	useEffect( () => {
		setOpen( openProp );
	}, [ openProp ] );
	// to support an accessibility value https://mui.com/material-ui/react-modal/#accessibility
	const titleId = useId();
	if ( title ) {
		muiModalPropsToSpread[ 'aria-labelledby' ] = titleId;
	}
	const descriptionId = useId();
	if ( description ) {
		muiModalPropsToSpread[ 'aria-describedby' ] = descriptionId;
	}
	return (
		<Dialog
			{ ...muiModalPropsToSpread }
			open={ open }
			onClose={ handleOnClose }
			maxWidth={ isMobile ? undefined : 'sm' }
			scroll='body'
			fullScreen={ isMobile }
			className={ classNames( muiModalProps.className, {
				[ styles.rootTextAlignCenter ]:
					variant && variant === MuiModalVariants.textAlignCenter,
			} ) }
			PaperProps={ {
				...muiModalPropsToSpread?.PaperProps,
				className: classNames(
					styles.root,
					muiModalPropsToSpread?.PaperProps?.className
				),
			} }
		>
			{ canClose ? (
				<IconButton
					aria-label='Close modal'
					data-cy='close-modal-button'
					className={ styles.closeButton }
					onClick={ ( e ) => handleOnClose( e, 'closeIcon' ) }
				>
					<Icon type='close-regular' />
				</IconButton>
			) : null }
			{ title ? (
				<DialogTitle id={ titleId } className={ styles.title }>
					{ title }
				</DialogTitle>
			) : null }
			{ description ? (
				<DialogContentText id={ descriptionId } className={ styles.description }>
					{ description }
				</DialogContentText>
			) : null }
			{ children ? (
				<DialogContent className={ styles.content }>{ children }</DialogContent>
			) : null }
			{ controls ? (
				<DialogActions className={ styles.controls }>{ controls }</DialogActions>
			) : null }
		</Dialog>
	);
}
